import React from "react";
import Services from "./BusinessComponents/Services";
import BusinessContact from "./BusinessComponents/BusinessContact";

const Businesses = () => {
  return (
    <div>
      <Services />;
      <BusinessContact />;
    </div>
  );
};

export default Businesses;
