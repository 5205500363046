import React, {useState} from "react";
import { Link } from "react-router-dom";
import "../../sharedPage/customCss/Custom.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  /*
  return (
    <div>
      <div className="mt-5">
        <div className="flex flex-col lg:flex-row-reverse">
          <div className="text-center lg:text-left rounded-lg">
            <img
              src="https://static.vecteezy.com/system/resources/previews/004/578/780/non_2x/girl-putting-up-sign-for-plan-schedule-free-vector.jpg"
              alt=""
            />
          </div>
          <div className="card flex-shrink-0 sm:w-full lg:max-w-sm  shadow-2xl bg-[#dcdcdc]   rounded-sm">
            <div className="card-body">
              <div className="form-control">
                <h1 className="text-5xl fonts font-bold mb-7"> Sign Up </h1>
                <label className="label">
                  <span className="text-xl text-slate-500">Email</span>
                </label>
                <input
                  type="text"
                  placeholder="Your Email"
                  className="input input-bordered"
                />
              </div>
              <div className="form-control">
                <label className="label">
                  <span className="text-xl text-slate-500">Password</span>
                </label>
                <input
                  type="text"
                  placeholder="Your password"
                  className="input input-bordered"
                />
                <label className="label">
                  <a href="." className="label-text-alt link link-hover">
                    Forgot password?
                  </a>
                </label>
                <label className="label">
                  <a href="." className="">
                    Already signed up?
                  </a>
                  <Link to="/login" className="link link-hover" style={{textDecoration: "underline"}}>
                    Log in
                  </Link>
                </label>
              </div>
              <div className="form-control mt-6">
                <button className="btn1 py-3 text-xl">Sign Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

   */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);

  const handleButtonClick = async (event) => {
    event.preventDefault();

    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail) {
      setShowError(true);
    } else {
      setShowError(false);

      const postData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
      };

      try {
        const response = await axios.post(
            "https://sheetdb.io/api/v1/sdiq0easp6f5x",
            postData
        );

        console.log("Server Response:", response.data);

        setFirstName("");
        setLastName("");
        setEmail("");
        toast.success("Thank you for joining!");
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Error submitting the form. Please try again.");
      }
    }
  };

  return (
      <div
          data-aos="zoom-in"
          className="py-12 bg-[#087f65] rounded hover:shadow-xl my-5 text-black"
      >
        <h1 className="text-5xl text-center font-bold font-serif mb-2">
          Join our waitlist
        </h1>
        <p className="text-center text-xl mb-7">
          Secure your spot to unlock exclusive access to our first micro-internship postings!
        </p>
        <div className="mt-5 flex flex-col items-center">
          <form
              className="flex flex-col gap-5 rounded p-5"
              onSubmit={handleButtonClick}

          >
            <div className="flex gap-5">
              <div className="flex-1">
                <label>
                  <input
                      type="text"
                      placeholder="First Name"
                      className="h-12 input-white rounded-xl w-full max-w-xs border-r-0 px-3 input input-bordered"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                  />
                </label>
              </div>
              <div className="flex-1">
                <label>
                  <input
                      type="text"
                      placeholder="Last Name"
                      className="h-12 input-black rounded-xl w-full max-w-xs px-3 input input-bordered"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <label>
              <input
                  type="text"
                  placeholder="Email"
                  className="h-12 input-black rounded-xl w-full max-w-m px-3 input input-bordered"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            {showError && (
                <div className="text-center text-red-500 mt-2">
                  Please enter a valid email.
                </div>
            )}
            <button className="bg-slate-700 h-12 text-slate-100 px-5 rounded-md">
              Submit{" "}
            </button>

          </form>
        </div>
      </div>
  );
};

export default Signup;
