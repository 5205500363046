import React from "react";

const Mission = () => {
  return (
    <div className="px-[140px] py-[90px] mt-[20px] bg-[#087f65;] rounded">
      {/* <div className="py-[130px] bg-[#087f65;] rounded hover:shadow-xl my-5"></div> */}
      <h1 className="text-5xl text-center text-gray-50 font-bold font-serif">
        Our Mission
      </h1>
      <p className="text-center text-gray-50 text-xl mt-[10px]">
        We connect students with micro-internships for hands-on learning and
        professional growth, fostering a bridge between education and industry
        success.
      </p>
    </div>
  );
};

export default Mission;
