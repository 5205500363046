import React from "react";
import Hero1 from "../hero/Hero1";
import Alljobs from "../alljobs/Alljobs";
import QuickStart from "./QuickStart";
import Contacts from "../contacts/Contacts";

const Students = () => {
  return (
    <div>
      <Hero1 />
      <Alljobs />
      <QuickStart />
      <Contacts />
    </div>
  );
};

export default Students;
