import React from "react";
import { Link } from "react-router-dom";

const Login = () => {

  return (
    <div className="mt-5">
      <div className="flex flex-col lg:flex-row-reverse">
        <div className="text-center lg:text-left ">
          <img
            src="https://static.vecteezy.com/system/resources/previews/003/267/157/non_2x/man-access-application-log-in-with-password-on-computer-free-vector.jpg"
            alt=""
          />
        </div>
        <div className="card flex-shrink-0 sm:w-full lg:max-w-sm shadow-2xl bg-[#dcdcdc] rounded-sm ">
          <div className="card-body">
            <div className="form-control">
              <h1 className="text-5xl fonts font-bold mb-7"> Log In</h1>
              <label className="label">
                <span className="text-xl text-slate-500">Email</span>
              </label>
              <input
                type="text"
                placeholder="Your Email"
                className="input input-bordered"
              />
            </div>
            <div className="form-control">
              <label className="label">
                <span className="text-xl text-slate-500">Password</span>
              </label>
              <input
                type="text"
                placeholder="Your password"
                className="input input-bordered"
              />
              <label className="label">
                <a href="." className="label-text-alt link link-hover">
                  Forgot password?
                </a>
              </label>
              <label className="label">
                <a href="." className="">
                 New Here?
                </a>
                <Link to="/signup" className=" link link-hover" style={{textDecoration: "underline"}}>
                  Sign Up
                </Link>
              </label>
            </div>
            <div className="form-control mt-6">
              <button className="btn1 py-3 text-xl">Log In</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
