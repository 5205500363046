import React from "react";
import Mission from "./Mission";
import Values from "./Values";
import History from "./History";

const About = () => {
  return (
    <div>
      <Mission />

      <History />
      <Values />
    </div>
  );
};

export default About;
