import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-10">
      <footer className="footer p-10 bg-base-300 text-base-content">
        <div>
          <span className="footer-title"> Our Services</span>
          <Link to="/CV-review" className="link link-hover">
            CV Review{" "}
          </Link>
          <a className="link link-hover">Find Job</a>
          <a className="link link-hover">Find Internship </a>
          <a className="link link-hover">User Support</a>
        </div>
        <div>
          <span className="footer-title">Company</span>
          <a className="link link-hover">About us</a>
          <a className="link link-hover">Contact</a>
          <a className="link link-hover">Jobs</a>
          <a className="link link-hover">Press kit</a>
        </div>
        <div>
          <span className="footer-title">Legal</span>
          <a className="link link-hover">Terms of use</a>
          <a className="link link-hover">Privacy policy</a>
          <a className="link link-hover">Cookie policy</a>
        </div>
      </footer>
      <footer className="footer px-10 py-4 border-t bg-base-200 text-base-content border-base-300">
        <div className="items-center grid-flow-col text-[#00ae87] font-semibold">
          <svg
            width="24"
            color="black"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            className="fill-current"
          >
            <path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path>
          </svg>
          <p>
            Ponte <br />
            Micro-internship seeking portal{" "}
          </p>
        </div>
        <div className="md:place-self-center md:justify-self-end">
        <div className="md:place-self-center md:justify-self-end">
        <div className="md:place-self-center md:justify-self-end">
  <div className="grid grid-flow-col gap-4 items-center">
    <a href="https://www.linkedin.com/company/99342044/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="#0077B5" // LinkedIn's official blue color
        className="fill-current text-blue-600" // Adjust color here
      >
        <path d="M20.25 0h-16.5c-1.242 0-2.25 1.008-2.25 2.25v19.5c0 1.242 1.008 2.25 2.25 2.25h16.5c1.242 0 2.25-1.008 2.25-2.25v-19.5c0-1.242-1.008-2.25-2.25-2.25zm-4.125 19.875h-2.25v-7.125h2.25v7.125zm-1.125-8.625c-1.242 0-2.25-.99-2.25-2.217 0-1.228 1.008-2.217 2.25-2.217s2.25.99 2.25 2.217c0 1.227-1.008 2.217-2.25 2.217zm5.625 8.625h-2.25v-4.5c0-1.242-.99-2.25-2.217-2.25s-2.217.99-2.217 2.25v4.5h-2.25v-7.125h2.25v1.062c.75-1.143 2.25-1.95 3.975-1.95 2.856 0 5.175 2.295 5.175 5.25v7.763z"></path>
      </svg>
    </a>
    <span className="text-gray-700 text-sm font-semibold">Follow us on LinkedIn</span>
  </div>
</div>

</div>

</div>

      </footer>
    </div>
  );
};

export default Footer;
