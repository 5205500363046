import { createBrowserRouter } from "react-router-dom";
import DetailsJob from "../components/alljobs/DetailsJob";
import Contacts from "../components/contacts/Contacts";
import Home from "../components/home/Home";
import Login from "../components/login/Login";
import Signup from "../components/Signup/Signup";
import Resources from "../components/resources/Resources";
import CVReview from "../components/FooterComponents/CVReview";
import About from "../components/about/About.js";
import Main from "../layout/Main";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/",
        // element: <Home />
        element: <Resources />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/contacts",
        element: <Contacts />,
      },

      {
        path: "/job/:details",
        element: <DetailsJob />,
      },
    ],
  },
]);

export default routes;
